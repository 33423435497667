import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import NextImage from 'components/NextImage';
import { NextLink } from 'components/NextLink';
import { ReactNode } from 'react';
import type { Banner2 as TBanner2 } from 'server/repository/hygraph/generated/graphqlTypes';
import { LocalizationService } from 'service/localization/localizationService';
import { AVAILABLE_THEMES, COLORS } from 'styles/constants';
import type { ISharedTemplateProps, UseExtendedImage } from 'templates/TemplateMapper/types';
import FadeInSection from '../../components/FadeInSection';
import { Grid, GridItem, GridRow } from '../../components/Grid';
import { FullBleedMobileSVG, FullBleedSVG } from './components';
import {
  ButtonContainer,
  ButtonMobileStyled,
  ButtonStyled,
  CurvesBothSides,
  FullBleedImage,
  FullBleedImageTextContainer,
  FullBleedImageTextContainerMobile,
  FullBleedImageTextWrapper,
  FullBleedText,
  ImageTextWrapper,
  ImageTextWrapperMobile,
  LeftImageContainer,
  LeftRightImageContainerMobile,
  LeftRightTextContainer,
  RightImageContainer,
  VerticalAlignMiddle,
} from './styles';
import { SuperTextElement } from './components/SuperTextElement';
import { TitleElement } from './components/TitleElement';
import { LiquidButtonElement } from './components/LiquidButtonElement';
import clsx from 'clsx';
import { DescElement } from './components/DescriptionElement';

export type Banner2TemplateProps = UseExtendedImage<
  TBanner2,
  ['backgroundAsset', 'mobileBackgroundAsset']
>;

export interface IBanner2Props extends ISharedTemplateProps {
  templateProps: Banner2TemplateProps;
  children?: ReactNode | ReactNode[];
  id?: string;
}

export const Banner2 = ({
  templateProps: {
    textAlignment,
    fullBleed = false,
    superText = '',
    title,
    link,
    description,
    buttonColor,
    backgroundAsset,
    mobileBackgroundAsset,
  },
  primarySubBrandColor,
  theme,
  themeName,
  id = 'banner2',
}: IBanner2Props) => {
  const { pathTranslations } = useGlobalContext();
  const btnHex = primarySubBrandColor
    ? primarySubBrandColor
    : buttonColor === 'Light'
      ? COLORS.secondary
      : COLORS.primary;
  const btnBgThemed = theme?.['color-neutral'] ?? btnHex;
  const textColorThemed =
    theme?.['color-tertiary'] ?? buttonColor === 'Light' ? COLORS.black : COLORS.white;

  const getLocalizedLink = {
    url: link?.url as string,
    internalLink: {
      ...link?.internalLink,
    },
  };
  const linkUrl = LocalizationService.getLocalizedLinkPath({
    item: getLocalizedLink,
    pathTranslations,
  });

  return (
    <div
      className={clsx({
        'mt-20': themeName !== AVAILABLE_THEMES.suprema,
      })}
      data-test={[`${id}-container`]}
    >
      {(fullBleed && (
        <>
          <FullBleedImageTextContainer>
            <FullBleedImageTextWrapper $textAlignment={textAlignment ?? 'Left'}>
              <FullBleedText data-test={[`${id}-full-bleed-text`]}>
                {superText && (
                  <FadeInSection delay="200ms">
                    <SuperTextElement
                      dataTest={`${id}-full-bleed-super-text`}
                      text={superText}
                      themeName={themeName}
                    />
                  </FadeInSection>
                )}
                {title && (
                  <FadeInSection delay="400ms">
                    <TitleElement
                      data-test={[`${id}-full-bleed-product-title`]}
                      themeName={themeName}
                      text={title}
                    />
                  </FadeInSection>
                )}
                {description && (
                  <FadeInSection delay="600ms">
                    <DescElement
                      dataTest={`${id}-full-bleed-description`}
                      themeName={themeName}
                      text={description}
                    />
                  </FadeInSection>
                )}
                {linkUrl && (
                  <FadeInSection delay="800ms">
                    <NextLink
                      href={linkUrl}
                      className="inline-block"
                      target={link?.openInNewTab ? '_blank' : '_self'}
                    >
                      <span className="sr-only">{link?.displayName}</span>
                      <ButtonContainer data-test={[`${id}-full-bleed-button`]}>
                        <ButtonStyled>
                          <LiquidButtonElement
                            text={link?.displayName ?? ''}
                            width={250}
                            ctaColor={btnHex}
                            textColor={buttonColor === 'Light' ? COLORS.black : COLORS.white}
                            themeName={themeName}
                          />
                        </ButtonStyled>
                      </ButtonContainer>
                    </NextLink>
                  </FadeInSection>
                )}
              </FullBleedText>

              <FullBleedImage data-test={[`${id}-full-bleed-image`]}>
                <FadeInSection delay="200ms">
                  <FullBleedSVG backgroundAsset={backgroundAsset?.asset[0]?.background || ''} />
                </FadeInSection>
              </FullBleedImage>
            </FullBleedImageTextWrapper>
          </FullBleedImageTextContainer>

          <FullBleedImageTextContainerMobile>
            <FullBleedImage data-test={[`${id}-full-bleed-mobile-image`]}>
              <FullBleedMobileSVG
                backgroundAsset={backgroundAsset?.asset[0]?.background || ''}
                mobileBackgroundAsset={mobileBackgroundAsset?.asset[0]?.background || ''}
              />
            </FullBleedImage>
            <FullBleedImageTextWrapper>
              <FullBleedText data-test={[`${id}-full-bleed-mobile-text`]}>
                {superText && (
                  <SuperTextElement
                    dataTest={`${id}-full-bleed-mobile-super-text`}
                    text={superText}
                    themeName={themeName}
                  />
                )}
                {title && (
                  <TitleElement
                    dataTest={`${id}-full-bleed-mobile-product-title`}
                    tag="h3"
                    themeName={themeName}
                    text={title}
                  />
                )}
                {description && (
                  <DescElement
                    dataTest={`${id}-full-bleed-mobile-description`}
                    tag="p"
                    text={description}
                    themeName={themeName}
                  />
                )}
                {linkUrl && (
                  <NextLink
                    href={linkUrl}
                    className="inline-block"
                    target={link?.openInNewTab ? '_blank' : '_self'}
                  >
                    <span className="sr-only">{link?.displayName}</span>
                    <ButtonContainer data-test={[`${id}-full-bleed-mobile-button`]}>
                      <ButtonMobileStyled>
                        <LiquidButtonElement
                          text={link?.displayName ?? ''}
                          width={150}
                          ctaColor={btnHex}
                          textColor={buttonColor === 'Light' ? COLORS.black : COLORS.white}
                          themeName={themeName}
                        />
                      </ButtonMobileStyled>
                    </ButtonContainer>
                  </NextLink>
                )}
              </FullBleedText>
            </FullBleedImageTextWrapper>
          </FullBleedImageTextContainerMobile>
        </>
      )) || (
        <>
          {(textAlignment === 'Right' && (
            <>
              <ImageTextWrapper>
                <CurvesBothSides className={`curve-banner-2 ${theme && 'bg-tertiary'}`}>
                  <Grid>
                    <GridRow columns={16} className="md:min-h-[inherit] relative">
                      <GridItem colStart={1} colSpan={8}>
                        <LeftImageContainer data-test={[`${id}-left-image`]}>
                          <FadeInSection delay="200ms">
                            {backgroundAsset?.asset[0]?.background && (
                              <div className="relative w-full h-full">
                                <NextImage
                                  src={backgroundAsset?.asset[0]?.background}
                                  mimeType={String(backgroundAsset?.asset[0]?.mimeType)}
                                  alt="banner 2 image"
                                  className="object-cover"
                                />
                              </div>
                            )}
                          </FadeInSection>
                        </LeftImageContainer>
                      </GridItem>
                      <GridItem colStart={10} colSpan={7}>
                        <VerticalAlignMiddle>
                          <LeftRightTextContainer data-test={[`${id}-left-text`]}>
                            {superText && (
                              <FadeInSection delay="200ms">
                                <SuperTextElement
                                  dataTest={`${id}-left-super-text`}
                                  text={superText}
                                  themeName={themeName}
                                />
                              </FadeInSection>
                            )}
                            {title && (
                              <FadeInSection delay="400ms">
                                <TitleElement
                                  dataTest={`${id}-left-product-title`}
                                  tag="h3"
                                  themeName={themeName}
                                  text={title}
                                />
                              </FadeInSection>
                            )}
                            {description && (
                              <FadeInSection delay="600ms">
                                <DescElement
                                  dataTest={`${id}-left-description`}
                                  text={description}
                                  themeName={themeName}
                                />
                              </FadeInSection>
                            )}
                            {linkUrl && (
                              <FadeInSection delay="800ms">
                                <NextLink
                                  href={linkUrl}
                                  className="inline-block"
                                  target={link?.openInNewTab ? '_blank' : '_self'}
                                >
                                  <span className="sr-only">{link?.displayName}</span>
                                  <ButtonContainer data-test={[`${id}-left-button`]}>
                                    <ButtonStyled>
                                      <LiquidButtonElement
                                        text={link?.displayName ?? ''}
                                        width={250}
                                        ctaColor={btnBgThemed}
                                        textColor={textColorThemed}
                                        hasTheme={!!theme}
                                        themeName={themeName}
                                      />
                                    </ButtonStyled>
                                  </ButtonContainer>
                                </NextLink>
                              </FadeInSection>
                            )}
                          </LeftRightTextContainer>
                        </VerticalAlignMiddle>
                      </GridItem>
                    </GridRow>
                  </Grid>
                </CurvesBothSides>
              </ImageTextWrapper>
            </>
          )) || (
            <ImageTextWrapper>
              <CurvesBothSides className={`curve-banner-2 ${theme && 'bg-tertiary'}`}>
                <Grid>
                  <GridRow columns={16} className="md:min-h-[inherit] relative">
                    <GridItem colStart={1} colSpan={7}>
                      <VerticalAlignMiddle>
                        <LeftRightTextContainer data-test={[`${id}-right-text`]}>
                          {superText && (
                            <FadeInSection delay="200ms">
                              <SuperTextElement
                                dataTest={`${id}-right-super-text`}
                                themeName={themeName}
                                text={superText}
                              />
                            </FadeInSection>
                          )}
                          {title && (
                            <FadeInSection delay="400ms">
                              <TitleElement
                                dataTest={`${id}-right-product-title`}
                                tag="h3"
                                text={title}
                                themeName={themeName}
                              />
                            </FadeInSection>
                          )}
                          {description && (
                            <FadeInSection delay="600ms">
                              <DescElement
                                dataTest={`${id}-right-description`}
                                text={description}
                                themeName={themeName}
                              />
                            </FadeInSection>
                          )}
                          {linkUrl && (
                            <FadeInSection delay="800ms">
                              <NextLink
                                href={linkUrl}
                                className="inline-block"
                                target={link?.openInNewTab ? '_blank' : '_self'}
                              >
                                <span className="sr-only">{link?.displayName}</span>
                                <ButtonContainer data-test={[`${id}-right-button`]}>
                                  <ButtonStyled>
                                    <LiquidButtonElement
                                      text={link?.displayName ?? ''}
                                      width={250}
                                      ctaColor={btnBgThemed}
                                      textColor={textColorThemed}
                                      hasTheme={!!theme}
                                      themeName={themeName}
                                    />
                                  </ButtonStyled>
                                </ButtonContainer>
                              </NextLink>
                            </FadeInSection>
                          )}
                        </LeftRightTextContainer>
                      </VerticalAlignMiddle>
                    </GridItem>
                    <GridItem colStart={9} colSpan={8}>
                      <RightImageContainer data-test={[`${id}-right-image`]}>
                        <FadeInSection delay="200ms">
                          {backgroundAsset?.asset[0]?.background && (
                            <div className="relative w-full h-full">
                              <NextImage
                                src={backgroundAsset?.asset[0]?.background}
                                mimeType={String(backgroundAsset?.asset[0]?.mimeType)}
                                alt="banner 2 image"
                                className="object-cover"
                              />
                            </div>
                          )}
                        </FadeInSection>
                      </RightImageContainer>
                    </GridItem>
                  </GridRow>
                </Grid>
              </CurvesBothSides>
            </ImageTextWrapper>
          )}
          <ImageTextWrapperMobile>
            <LeftRightImageContainerMobile
              data-test={[`${id}-left-mobile-image`]}
              className="curve-banner-2-mobile-top bg-tertiary"
            >
              {(mobileBackgroundAsset?.asset[0]?.background ||
                backgroundAsset?.asset[0]?.background) && (
                <div className="relative w-full h-full min-h-354">
                  <NextImage
                    src={
                      mobileBackgroundAsset?.asset[0]?.background ||
                      backgroundAsset?.asset[0]?.background ||
                      ''
                    }
                    mimeType={String(
                      backgroundAsset?.asset[0]?.mimeType ||
                        mobileBackgroundAsset?.asset[0]?.mimeType,
                    )}
                    alt="banner 2 image"
                    className="object-cover"
                  />
                </div>
              )}
            </LeftRightImageContainerMobile>
            <LeftRightTextContainer
              data-test={[`${id}-left-mobile-text`]}
              className={theme && 'curve-banner-2 bg-tertiary !-mt-8 !pt-16 !pb-10'}
            >
              {superText && (
                <SuperTextElement
                  dataTest={`${id}-left-mobile-super-text`}
                  themeName={themeName}
                  text={superText}
                />
              )}
              {title && (
                <TitleElement
                  dataTest={`${id}-left-mobile-product-title`}
                  text={title}
                  themeName={themeName}
                />
              )}
              {description && (
                <DescElement
                  dataTest={`${id}-left-mobile-description`}
                  text={description}
                  themeName={themeName}
                />
              )}
              {linkUrl && (
                <NextLink
                  href={linkUrl}
                  className="inline-block"
                  target={link?.openInNewTab ? '_blank' : '_self'}
                >
                  <span className="sr-only">{link?.displayName}</span>
                  <ButtonContainer data-test={[`${id}-left-mobile-button`]}>
                    <ButtonMobileStyled>
                      <LiquidButtonElement
                        text={link?.displayName ?? ''}
                        width={150}
                        ctaColor={btnBgThemed}
                        textColor={textColorThemed}
                        hasTheme={!!theme}
                        themeName={themeName}
                      />
                    </ButtonMobileStyled>
                  </ButtonContainer>
                </NextLink>
              )}
            </LeftRightTextContainer>
          </ImageTextWrapperMobile>
        </>
      )}
    </div>
  );
};
